.errorpage{
    height: 100%;
    text-align: center;
    font-size: large;
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.errorpage h1{
    font-size: 50px;
    margin: 0;
   font-style: italic;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
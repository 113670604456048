/* General container styling */
.rolemaster {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #ffffff;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    font-family: 'Arial', sans-serif;
    font-size: 15px;
    position: relative;
    top: 10px;
}

/* Header styling */
/* .rolemaster h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
} */

/* Form styling */
.rolemaster form {
    width: 100%;
    /* margin-top: 20px; */
}

/* Table styling */
.rolemaster table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.rolemaster th, .rolemaster td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.rolemaster th {
    background-color: #243847;
    color: #ffffff;
    font-weight: 600;
}
.rolemaster .role{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px;
}
.rolemaster input[type="text"] {
    width: 75%;
    padding: 8px;
    margin-left: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.rolemaster input[type="text"]:focus {
    border-color: #007bff;
}

/* Checkbox styling */
.rolemaster input[type="checkbox"]{
    transform: scale(1.2);
    cursor: pointer;
    accent-color: #007bff; 
}

/* Button styling */
#btn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 60px;
}

.rolemaster button {
    background-color: #243847;
    color: #ffffff;
    border: 1px solid #243847;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

/* .rolemaster button:hover {
    background-color: #ffffff;
    color: #243847;
    border: 1px solid #243847;
    transform: translateY(-2px);
} */

.rolemaster button:active {
    background-color: #ffffff;
    transform: translateY(0);
}

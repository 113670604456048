/* Main container for split layout */
.container1 {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
  }
  
  /* Left image section styling */
  .container1 .left-section {
    flex: 1;
    background-image: url('../images/Background.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .container1 .left-section img{
    position: absolute;
    top: 30%;
    left: 9%;
  }
  .container1 .left-section div{
    position: absolute;
    top: 43%;
    left: 9%;
    border-left: 5px solid #243847;
    padding-left: 20px;
  }
  .container1 .left-section h6{
    font-size: large;
    color: rgb(225, 13, 13);
    text-shadow: 0 0 8px #ffffff70;
  }
  .container1 .left-section p{
    color: rgb(58, 56, 56);
    width: 50%;
    font-size: 35px;
    font-weight: 100;
    font-family: 'Times New Roman', Times, serif;
  }
  
  /* Right login form section styling */
  .container1 .right-section {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffffff; 
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    background: #ffffffe2;
    padding: 30px;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
  .form-container img {
    display: block;
    margin: 0 auto 20px;
    width: 115px;
    height: 20px;
  }
  
  .form-container label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-container input[type="text"],
  .form-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-container button[type="button"] {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 35px;
    font-size: 1.2em;
    color: #666;
  }
  
  .form-container button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #243847;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .form-container button[type="submit"]:hover {
    background-color: #243847ec;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
    .left-section, .right-section {
      flex: none;
      height: 50vh;
    }
  }
  
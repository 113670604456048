.custom-input1 {
  text-align: center;
}

.custom-input2 {
  text-align: center;
  width: 20% !important;
}

.custom-button {
  width: 160px !important;
}

.right-align {
  display: flex !important;
  justify-content: end;
}

/* .right-align1{

} */


.form-check-input[type=checkbox] {
  border-color: #b4b3b3;
  border-radius: 0.35em !important;
}

.custom-confirm-button {
  background-color: #243847;
  color: white;
}

.custom-cancel-button {
  background-color: #f44336;
  color: white;
}


/* DataTableStyles.css */
.data-table-container {
  font-size: 16px;
  border: 1px solid black;
  table-layout: auto;
}

.data-table-container {
  width: 100%;
  /* Make sure the table container can expand */
  overflow-x: auto;
}

/* Headings */
.data-table-container .rdt_TableHead {
  font-size: 15px;
  justify-content: center;
}

.data-table-container .rdt_TableHeadRow {
  justify-content: center;
  background: #243847;
  color: #FFFFFF;
  /* border: 1px solid white; */
}

.data-table-container .rdt_TableCol {
  justify-content: center;
}

/* Body rows */
.data-table-container .rdt_TableBody {
  font-size: 14px;
  background: #243847;
}


/* Center all text content */
.data-table-container .rdt_TableCell {
  border: 1px solid black;
  justify-content: center;
  font-size: 14px;
}

.custom-toast-popup {
  width: 400px;
  height: 180px;
}

.custom-toast-popup-2 {
  width: 400px;
  height: 180px;
}

.custom-toast-popup-3 {
  width: 400px;
  height: 200px;
}

.custom-toast-icon {
  font-size: 5px;
}

.custom-toast-title {
  font-size: 20px;
}

.custom-confirm-button,
.custom-cancel-button {
  font-size: 13px !important;
}



/* Tab.css */
.tab {
  display: flex;
  align-items: center;
  /* justify-content: center;      */
  /* cursor: pointer; */
  padding: 2px;
  /* margin: 1px; */
  border-radius: 4px;
  transition: background-color 0.3s ease;
}


/* Dashboard.css */
.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  box-sizing: border-box;
}

.tab-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-main {
  background-color: #F5F7FA;
  color: black;
  width: 72%;
  position: relative;
  bottom: 1%;
}

.tab-row {
  display: flex;
  justify-content: space-around;
  /* padding: -100px;  */
  /* background-color: #f1f1f1; */
}

.tab-secondary {
  background-color: #e0e0e0;
  color: #333;
  width: 322px;
  height: 165px;
  font-size: 20px;
  text-align: center;
  justify-content: center;
}

.dashimg {
  width: 40px;
  height: 40px;
}

.svgdashlogo {
  position: relative;
  left: 44%;
  /* align-items: end; */
}

.da-hello-tag {
  position: relative;
  left: 2%;
}

.da-msg {
  /* position: relative;
  top:2%; */
  font-size: 10px;
}

/* loginpagestyle */
.loginpage-loginbutton {
  position: absolute;
  left: 42%;
}

.loginpage-lufteklogo {
  position: absolute;
  left: 18%;
  width: 283px;
  height: 47px;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container .form-control {
  padding-right: 40px;
  /* Adjust padding to make space for the icon */
}

.password-toggle-button {
  position: absolute;
  right: 59px;
  /* Position icon within the input field */
  bottom: 112px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #3d3a3a;
  /* Adjust color as needed */
}

.password-toggle-button:hover {
  color: #000;
  /*Change color on hover*/
}

/* registerpage style */
.registerpage-lufteklogo {
  position: absolute;
  left: 18%;
  width: 287px;
  height: 47px;
}

.role-create-btn {
  width: 100px !important;
  /* margin-bottom: 20px; */
  position: relative;
  left: 55%;
  color: #243847;
  background-color: #243847;
}

/* .rolemaster-back-btn{
  width: 100px !important;
  margin-bottom: 20px;
  position: relative;
  left:1%;
  color: #243847;
  background-color: #243847;
}

.rolemaster-savepermission-btn{
  width: 200px !important;
  margin-bottom: 20px;
  position: relative;
  left:41%;
  color: #243847;
  background-color: #243847;
} */

.user-register-create-btn {
  width: 120px !important;
  margin: 30px 0;
  position: relative;
  left: 4%;
  color: #243847;
  background-color: #243847;
}

.user-register-back-btn {
  width: 100px !important;
  margin: 30px 0;
  position: relative;
  left: 0%;
  color: #243847;
  background-color: #243847;
}

.user-page-create-btn {
  width: 100px !important;
  /* margin: 30px 0; */
  position: relative;
  left: 78%;
  color: #243847;
  background-color: #243847;
}

.dropdown-item:active {
  background-color: black !important;
  /* Color for active item */
  color: #000;
  /* Text color for active item */
}

.button-color {
  background-Color: #243847;
  border-Color: #243847;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Makes it full-screen height */
  background-color: rgba(255, 255, 255, 0.8);
  /* Slight background overlay */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  /* Blue spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* BOM-Upload */
.bom-table table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  margin-top: 20px;
}

.bom-table thead th {
  background-color: #243847;
  color: white;
  padding: 10px;
}

.bom-table tbody td {
  padding: 8px;
  border: 1px solid #ddd;
}
.bom-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.bom-table tr:hover {
  background-color: #ddd;
}

.bom-table table th,.bom-table table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.bom-table th {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

.bom-table tbody td {
  text-align: center;
}

#hide-icon{
  display: none;
}



/* Loadingslipprint */
.loadingslipprint,
.pickslipprint {
  margin: 30px;
}

/* Set page size for A4 */
/* @page {
  size: A4;
  margin: 20mm;
} */

/* Ensure that the packing list container is A4 size */
.packing-list-container {
  width: 210mm;
  /* A4 width in millimeters */
  min-height: 297mm;
  /* A4 height in millimeters */
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid black;
}

/* General styles for headers, tables, and layout */
.header {
  padding: 7px;
  border-bottom: 1px solid black;
}
.logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

.company-details {
  text-align: center;
  position: relative;
  left: 30px;
  width: 90%;
}

.logo h1 {
  font-weight: bold;
  font-size: 24px;
}

.company-details p {
  font-size: 14px;
}

.packing-list-table {
  font-weight: bold;
}

.packing-list-table,
.item-list-table,
.packing-list {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}

.packing-list-table th,
.packing-list-table td,
.item-list-table th,
.item-list-table td {
  border: 1px solid black;
  padding: 9px;
  text-align: center;
  font-size: 0.9rem;
}

.item-list-table thead,
.item-list-table #table-footer {
  background-color: #e0e0e0;
  font-weight: bold;
}

.footer {
  margin-top: 20px;
  font-size: 0.9rem;
}

.signature-block {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid black;
  padding-top: 10px;
  font-weight: bold;
}

/* Printing styles */
@media print {
  .packing-list-container {
    width: 100%;
    max-height: 90vh;
    min-height: auto;
    box-shadow: none;
    margin: 0;
    padding: 10px;
    border: none;
    /* page-break-inside: avoid; */
    display: block;
  }

  .jtn-footer {
    position: fixed;
    bottom: -2px;
    right: 0;
    font-weight: bold;
    font-size: 0.8rem;
    /* display: block; */
    }

  body {
    margin: 0;
    counter-reset: page;
  }
  /* .print-header::after {
    content: "Page " counter(page);
  }

  .print-header {
    position: running(header);
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  } */

  /* Page footer styling */
  /* .page-number {
    position: running(footer);
    width: 100%;
    text-align: right;
    padding: 10px;
    border-top: 1px solid #ddd;
  } */
  .page-number::after {
    content: "Page " counter(page-counter);
    counter-increment: page-counter;
    position: fixed;
    bottom: -2px;
    left: 10px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .packing-list-table,
  .item-list-table {
    font-size: 0.8rem;
    /* Adjust for print readability */
  }
  .footer{
    break-inside: avoid;
  }
}

.custom-mb {
  margin-top: 40px;
}

table.custom-table {
  border: 1px solid black;
  width: 100%;
}

table.custom-table th {
  background-color: #243847;
  color: white;
  /* width: 10px; */
}
table.custom-table th, table.custom-table td{
  border-right: 1px solid black;
  text-align: center;
}